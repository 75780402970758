import React from "react";
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import Layout from '../components/layout/layout';
import Baner404 from '../components/baner404/Baner404';

export default function page404({ data }) {

  const img404 = data.img404.childImageSharp.fluid

  return <div>
    <SEO title="404" />
    <Layout>
      <Baner404
        fluid={img404}
        to='/'
        button='strona główna'
        header1='404'
        header2='Uuups... Wygląda na to że strona o podanym adresie nie istnieje. Zapraszamy na stronę główną.' />
    </Layout>
  </div>
}

export const query = graphql`
query {
  img404: file(relativePath: { eq: "main_image2.jpg" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 1000) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
}
`
