import React from 'react';
import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Container } from '../utils/utils';
import Button from '../button/Button';

const Baner = styled(BackgroundImage)`
background-color: #d7edf5;
flex-grow: 1;
min-height: 50vh;

> div {
    flex-grow: 1;
    ::before,
    ::after {
        background-size: contain !important;
        background-position-x: right !important;
        }
}
`;

const BanerInnerInfo = styled.div`
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: ${({ theme }) => theme.color.opacityBlack06};
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
        display: flex;
        flex-direction: column;
        align-items: center;

        h1, h2 {
            color: ${({ theme }) => theme.color.white};
            text-align: center;
            margin-bottom: 3rem;
        }

        h1 {
            font-size: 80px;
        }
    }
`;


const Baner404 = (props) => (
    <Baner
        backgroundColor={`#9cc2d3`}
        fluid={props.fluid}>

        <BanerInnerInfo>
            <Container>
                <h1>{props.header1 || 'header1'}</h1>
                <h2>{props.header2 || 'header2'}</h2>
                {props.children}

                {props.button === undefined ? (
                    <></>
                ) : (
                        <Button to={props.to}>
                            {props.button}
                        </Button>
                    )}
            </Container>
        </BanerInnerInfo>
    </Baner>
);

export default Baner404;